<template>
  <div class="point-detail h-100">
    <Row type="flex" align="middle" class="header h-6">
        <Col span="3" class="text-c"><Icon custom="iconfont icon-fanhui1" class="f-20 c-white c-h" @click="$router.back()" /></Col>
        <Col span="18" class="text-c f-16 c-white">研学点</Col>
        <Col span="3" class="text-c">
            <Icon type="md-contact" size="24" class="c-white c-h" />
        </Col>
    </Row>
    <div ref="map" class="h-34 map-wrap">
        <div class="tool-wrap">
            <button class="tool-button" @click="setNavigation">指北针</button>
            <button class="tool-button" @click="rotate">绕点</button>
            <button class="tool-button" @click="birdEyeView">鸟瞰</button>
            <button class="tool-button" @click="lookDown">俯视</button>
            <button class="tool-button" @click="lookAt">平视</button>
            <button class="tool-button" >全景</button>
        </div>
    </div>
    <div class="h-60 p-10 detail-content">详细信息</div>
  </div>
</template>

<script>
import { CesiumMap } from '@/libs'
import DrawTool from '@/libs/mapByCesium/DrawTool.js'
import Earth from "./components/Earth.vue";
import { getPointById } from '@/libs/api/point'
export default {
    components: { Earth },
    data() {
        return {
            detail: {},
            rotateBool: false,  // 是否旋转
        }
    },
    methods: {
        // 罗盘
        setNavigation() {
            this.cesium.setNavigation()
        },
        // 绕点
        rotate() {
            if(!this.tool.rotateBool) 
                this.tool.rotateCamera(this.detail)
            else
                this.tool.stopRotate()
        },
        // 鸟瞰 90度
        birdEyeView() {
            this.tool.birdEyeView(this.detail, 90)
        },
        // 俯视 45度默认值
        lookDown() {
            this.tool.birdEyeView(this.detail)
        },
        // 平视 90度
        lookAt() {
            this.tool.birdEyeView(this.detail, 5)
        },
        getData() {
            // 获取研学点详情API,显示在cesium
            getPointById({ id: this.$route.query.id }).then(res => {
                if(res.code == 'HA0200') {
                    Object.assign(this.detail, { 
                        ...res.data,
                        id: '0',
                        name: res.data.pointName,
                        lng: res.data.pointCoord.lon,
                        lat: res.data.pointCoord.lat,
                        ele: res.data.pointCoord.ele / 1,
                        width: 20,
                        height: 20,
                        image: 'leida.svg'
                    })
                    // console.log('this.detail', this.detail)
                    const { lng, lat, ele } = this.detail
                    // this.tool.addPoint(this.detail)
                    this.cesium.pointCamera(lng, lat, ele)
                }
            })
            
        },

    },
    async mounted() {
        // 加载cesium
        const cesium = await new CesiumMap(this.$refs.map, {
            homeButton: false,
            sceneModePicker: false,
            isCredit: true,
            isAnimation: true,
            isTimeline: true
        })
        this.cesium = cesium
        this.tool = new DrawTool(cesium)
        this.getData()
    }
}
</script>

<style lang="less" scoped>
.point-detail {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #333;
    .map-wrap {
        position: relative;
        .tool-wrap {
            z-index: 9;
            height: 90%;
            position: absolute;
            right: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .tool-button {
                padding: 2px 5px;
                background: #fff;
                color: #333;
                outline: none;
                border: none;
                border-radius: 5px;
                &:hover {
                background: #5cadff;
                color: #fff;
                }
            }
        }
        
    }
    .detail-content {
        overflow-y: scroll;
    }
}
</style>